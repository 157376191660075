@import "styles/styles.scss";
.SizingWidget {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin-bottom: 50px;
  max-width: 100%;
  background: $color-neutral-min;

  @include tablet-up {
    margin-bottom: 80px;
  }

  &__container {
    margin: 0 auto;
    max-width: 375px;
    position: relative;
    width: 100%;
    padding: 0 14px;
    text-align: center;

    @include tablet-up {
      max-width: inherit;
    }
  }

  &:not(.SizingWidget__mobile__wrapper) {
    .SizingWidget__container {
      @include desktop-up {
        width: 100%;
        max-width: 980px;
      }
    }
  }

  &__wrapper {
    display: flex;

    @include desktop-up {
      width: 100%;
      max-width: 980px;
    }
  }
}

.SizingWidget__mobile__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $color-neutral-min;
  padding-bottom: 40px;

  @media (min-width: 500px) {
    padding-bottom: 0;
  }

  &.SizingWidget__mobile__wrapper_product {
    background: $color-neutral-min;
    margin: 0 auto;
    padding-bottom: 60px;
    width: calc(100% - 40px);
  }

  .SizingWidget__mobile {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    height: 570px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    background: $color-neutral-min;
  }
}

.SizingWidget__button {
  margin-top: 30px;
  margin-left: auto;

  @include desktop-up {
    margin-top: 0;
    margin-left: 0;
    position: absolute;
    top: 16px;
    right: 14px;
  }
}